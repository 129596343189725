import Vue from "vue";
import Router from "vue-router";

// Login/forgotpwd/register
import SharedRoutes from "./sharedRoutes";

//-----Admin related routes
import AdminRoutes from './adminRoutes'
import Librarian from './LibrarianRoutes'


// learner routes
//import LearnerRoutes from './learnerRoutes'
import SuperAdminRoutes from './superAdminRoutes'
import MemberRoutes from './MemberRoutes'

import ManagementRoutes from './ManagementRoutes'

// Instructor routes
//import InstructorRoutes from './instructorRoutes'

Vue.use(Router);

var allRoutes = [];
//allRoutes = allRoutes.concat(AdminRoutes, SharedRoutes,LearnerRoutes,SuperAdminRoutes,InstructorRoutes)
allRoutes = allRoutes.concat(SharedRoutes, AdminRoutes, Librarian, SuperAdminRoutes,MemberRoutes,ManagementRoutes);

const routes = allRoutes;

export const router = new Router({
    routes: routes, //routes:routes
    mode: "history", //  history/hash
    linkActiveClass: "active", // active class for non-exact links.
    linkExactActiveClass: "active" // active class for exact links.
});