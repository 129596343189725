const Login = () =>
    import ("@/components/login");
const Test = () =>
    import ("@/components/Test");
const Header = () =>
    import ("@/components/Header");
const Sidebar = () =>
    import ("@/components/Sidebar");
const Home = () =>
    import ("@/components/Home");
const ForgotPassword = () =>
    import ("@/components/ForgotPassword");
const updatePassword = () =>
    import ('@/components/UpdatePassword');
// const AdminMaster = () =>
//     import ("@/components/Admin/AdminMaster.vue");
const routes = [{
        path: "/",
        name: "login",
        component: Login,
        meta: {
            breadcrumb: "Login" //crumb
        }
    },
    {
        path: "/forgot-password",
        name: "forgot-password",
        component: ForgotPassword,
        meta: {
            breadcrumb: "Login" //crumb
        }
    },
    {
        path: '/update-password',
        name: "update-password",
        component: updatePassword,
        meta: {
            breadcrumb: "Login" //crumb
        }
    },
    {
        path: "/test",
        name: "test",
        component: Test,
        meta: {
            breadcrumb: "Login" //crumb
        }
    },
    {
        path: "/home",
        name: "home",
        component: Home,
        meta: {
            breadcrumb: "Login" //crumb
        }
    },
    {
        path: "/header",
        name: "header",
        component: Header,
        meta: {
            breadcrumb: "Login" //crumb
        }
    },
    {
        path: "/sidebar",
        name: "sidebar",
        component: Sidebar,
        meta: {
            breadcrumb: "Login" //crumb
        }
    },
];
export default routes;